<template>
  <div class="info-box">
    <div class="left">
      <div class="left-top">
        <div style="display: flex;flex-direction: row;flex: 1">
          <div style="padding-right: 20px">
            <el-image :src="global.apiUri+detail.cover" style="width: 180px;height: 250px" fit="cover"></el-image>
          </div>
          <div style="padding-right: 10px;box-sizing: border-box">
            <p class="book-title">{{ detail.title }}</p>
            <p style="color: #5a5e66;font-size: 16px">作者：{{ detail.author }}</p>
            <p style="color: #5a5e66">分类：{{ detail.cate }}</p>
            <p style="color: #5a5e66">出版社：{{ detail.publisher }}</p>
            <p style="color: #5a5e66">发布于: {{ detail.date }}</p>
          </div>
        </div>
        <div class="right-btn">
          <el-button type="danger" size="small" round @click="read">立即阅读</el-button>
          <el-button type="danger" size="small" plain round v-if="!userBookInfo.is_bookshelf" @click="bookshelf(1)">
            加入书架
          </el-button>
          <el-button type="danger" size="small" plain round v-else @click="bookshelf(0)">取消加入书架</el-button>

          <el-button type="danger" size="small" plain round v-if="!userBookInfo.is_collection" @click="collection(1)">
            收藏
          </el-button>
          <el-button type="danger" size="small" plain round v-else @click="collection(0)">取消收藏</el-button>

        </div>
      </div>
      <el-divider></el-divider>
      <div>
        <i style="font-size: 20px;color:rgb(48, 176, 143)" class="el-icon-s-management"><span
            style="color: black"> 简介</span></i>
        <div style="font-size: 14px;margin-top: 20px">{{ detail.desc }}</div>
        <el-empty v-if="!detail.desc" description="暂无简介"></el-empty>
      </div>
      <!--      <el-divider></el-divider>-->
      <div style="display: none">
        <i style="font-size: 20px;color:rgb(48, 176, 143)" class="el-icon-chat-dot-round"><span style="color: black"
        > 评论</span></i>
        <el-divider></el-divider>
        <el-form ref="form" label-width="80px">
          <el-form-item label="发表评论">
            <el-input type="textarea" v-model="commentText"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitComment">发表</el-button>
          </el-form-item>
        </el-form>
        <el-divider></el-divider>

        <!--        <MainComment v-for="(comment, index) in commentList" :key="index" :commentData="comment"/>-->

        <p style="padding-left: 20px" v-if="commentListTotal === 0">暂无评论</p>

      </div>
    </div>
    <div class="right">
      <el-card>
        <div slot="header" class="clearfix">
          <span>作者其他作品</span>
        </div>
        <div class="shuku-left-box-line" v-for="(o, index) in authorList" :key="index">
          <div class="shuku-left-box" v-if="index<=2" @click="openBookInfo(o.bookId)">
            <div class="book-title" @click="openBookInfo(o.bookId)">{{ o.title }}</div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import MainComment from "../components/comment/mainComment";

export default {
  components: {MainComment},
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 9) {
        return value.slice(0, 9) + '...'
      }
      return value
    }
  },
  provide() {
    return {
      fatherMethod: this.getComList
    }
  },
  data() {
    return {
      isAds: true,
      commentListTotal: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      bookId: '',
      userBookInfo: '',
      detail: {},
      commentText: '',
      authorList: [],
      commentList: [],
    }
  },

  created() {
    this.bookId = this.$route.query.bookId;
    setTimeout(() => {
      //异步执行列表刷新
      this.getBookInfo();
      this.getMyBookInfo();
      this.getauthorInfo();
      this.getComment();
    }, 0)

  },

  methods: {
    submitComment() {
      let _this = this;
      let params = {};
      params.bookId = _this.bookId;
      params.commentText = _this.commentText;
      _this.axios.post('/user/add-comment', params)
          .then(function (res) {
            if (res.code == 200) {
              _this.commentText = '';
              _this.$message.success('评论成功');
              _this.handleClose();
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    bookshelf(type) {
      let _this = this;
      let url = '';
      if (type == 1) {
        url = 'user/add_bookshelf';
      } else {
        url = 'user/del_bookshelf';
      }
      _this.axios.get(url + '?bid=' + _this.bookId)
          .then(function (res) {
            if (res.code == 200) {
              _this.getMyBookInfo();
              _this.$message.success('操作成功');
            } else {
              _this.$message.error(res.msg);
            }
          });
    },
    collection(type) {
      let _this = this;
      let url = '';
      if (type == 1) {
        url = 'user/add_collection';
      } else {
        url = 'user/del_collection';
      }
      _this.axios.get(url + '?bid=' + _this.bookId)
          .then(function (res) {
            if (res.code == 200) {
              _this.getMyBookInfo();
              _this.$message.success('操作成功');
            } else {
              _this.$message.error(res.msg);
            }
          });
    },

    getBookInfo() {
      let _this = this;
      _this.axios.get('/ebook/book_info?bid=' + _this.bookId)
          .then(function (res) {
            if (res.code == 200) {
              _this.detail = res.data;
            } else {
              _this.$message.error(res.msg);
            }
          });
    },
    getMyBookInfo() {
      let _this = this;
      _this.axios.get('/user/book_info?bid=' + _this.bookId)
          .then(function (res) {
            if (res.code == 200) {
              _this.userBookInfo = res.data;
            }
          });
    },
    getauthorInfo() {
      let _this = this;
      _this.axios.get('/ebook/get-bookbyauthor?bid=' + _this.bookId)
          .then(function (res) {
            if (res.code == 200) {
              _this.authorList = res.data;
            } else {
              _this.$message.error(res.msg);
            }
          });
    },
    getComment() {
      let _this = this;
      _this.axios.get('/ebook/get-comment?bid=' + _this.bookId)
          .then(function (res) {
            if (res.code == 200) {
              _this.commentList = res.data;
            } else {
              _this.$message.error(res.msg);
            }
          });
    },
    read() {
      if (this.detail.is_add == 1) {
        this.$router.push({path: '/book/reader', query: {bookId: this.bookId}});
      } else {
        if (this.detail.file_type == 'text/plain') {
          this.$router.push({path: '/book/reader_txt', query: {bookId: this.bookId}});
        } else {
          this.$router.push({path: '/book/reader_pdf', query: {bookId: this.bookId}});
        }
      }

    },
    openBookInfo(bookId) {
      if (bookId == this.bookId) {
        return false;
      }
      this.$router.push({path: '/book/bookInfo', query: {bookId: bookId}});
    }
  }

}
</script>

<style scoped lang="less">
.info-box {
  width: 1200px;
  margin: 40px auto;
  display: flex;
  flex-direction: row;

  .left {
    width: 800px;
    padding-right: 30px;
    box-sizing: border-box;

    .left-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between
    }
  }

  .right {
    width: 400px;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .right-btn {
    width: 100px;

    .el-button {
      margin-left: 0 !important;
      width: 100px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  .book-title {
    font-weight: 600;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //将对象作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; // 从上到下垂直排列子元素
    -webkit-line-clamp: 3; //显示的行数
  }
}

.shuku-left-box-line {
  width: 400px;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;

}

.shuku-left-box {
  width: 300px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;

  .book-title {
    //font-weight: 300;
    font-size: 14px;
  }
}
</style>
