<template>
  <div>
    <el-link :underline="false" @click="toZuye(parentData.sysUser.userId)">
      <el-avatar :src="baseUrl+parentData.sysUser.avatar" style="vertical-align: middle;">
      </el-avatar>
      <span style="font-weight:bold;font-size: 20px;margin-left: 10px">{{ parentData.sysUser.nickName }}</span>
    </el-link>
    <span style="font-style: italic;color: #5a5e66;margin-left: 10px"> {{ parentData.createDate }}</span>
    <p style="padding-left: 20px">{{ parentData.isVio == 1 ? parentData.commentText : "该评论涉嫌违规" }}</p>
    <span style="font-style: italic;color: #5a5e66;margin-left: 10px"> IP属地：{{ parentData.sysUser.loginIp }}</span>
    <div style="display: block;float: right">
      <el-button type="text" @click="huifu(parentData.commentId)" icon="el-icon-chat-dot-round">回复</el-button>
      <el-button type="text" @click="like(parentData)" icon="el-icon-star-on"
                 :style="!flag ? 'color: #5a5e66':'color: #6289ec'">
        {{ parentData.likeNum }}
      </el-button>
    </div>
    <div v-show="comShow">
      <el-input v-model="form.commentText" :placeholder="'回复 '+ parentData.sysUser.nickName">
        <el-button slot="append" icon="el-icon-s-promotion" @click="onSubmit">发布</el-button>
      </el-input>
    </div>
    <el-divider></el-divider>
    <div style="margin-left: 40px">
      <parent v-for="(comment, index) in parentData.children" :key="index" :parentData="comment" v-if="comment"/>
    </div>
  </div>
</template>

<script>

export default {
  name: "parent",
  props: ["parentData"],
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      flag: false,
      comShow: false,
      form: {}
    }
  },
  inject: ["fatherMethod"],
  methods: {}
}
</script>

<style scoped>

</style>
