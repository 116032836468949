<template>
  <div>
    <el-link :underline="false">
      <el-avatar :src="1" style="vertical-align: middle;">
      </el-avatar>
      <span style="font-weight:bold;font-size: 20px;margin-left: 10px">{{ commentData.username }}</span>
    </el-link>
    <span style="font-style: italic;color: #5a5e66;margin-left: 10px"> {{ commentData.createDate }}</span>
    <div style="display: block;float: right">
      <el-button type="text" @click="huifu(commentData.commentId)" icon="el-icon-chat-dot-round">回复</el-button>
      <el-button type="text" @click="like(commentData)" icon="el-icon-star-on"
                 :style="!flag ? 'color: #5a5e66':'color: #6289ec'">
        {{ commentData.likeNum }}
      </el-button>
    </div>
    <div v-show="comShow">
      <el-input v-model="form.commentText" :placeholder="'回复 '+ commentData.sysUser.nickName">
        <el-button slot="append" icon="el-icon-s-promotion" @click="onSubmit">发布</el-button>
      </el-input>
    </div>
    <el-divider></el-divider>
    <div style="margin-left: 40px">
      <parentCommit v-for="(comment, index) in commentData.children" :key="index" :parentData="comment" v-if="comment"/>
    </div>
  </div>
</template>

<script>

import parentCommit from "./parentComment"

export default {
  props: ["commentData"],
  components: {
    parentCommit
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      flag: false,
      comShow: false,
      form: {}
    }
  },
  inject: ["fatherMethod"],
  methods: {
  }
}
</script>

<style scoped>

</style>
